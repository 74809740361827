// src/pages/Gallery/Gallery.tsx

import React from "react";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import GenericGallery from "../../components/GenericGallery/GenericGallery";
import HomeHero4 from "../../assets/images/HomeHero4.jpg";
import styles from "./Gallery.module.css";

import MainRoom1 from "../../assets/images/MainRoom1.jpg";
import MainRoom2 from "../../assets/images/MainRoom2.jpg";
import MainRoom3 from "../../assets/images/MainRoom3.jpg";
import Room1 from "../../assets/images/Room1.jpg";
import Room2 from "../../assets/images/Room2.jpg";
import Twin1 from "../../assets/images/Twin1.jpg";
import Twin2 from "../../assets/images/Twin2.jpg";
import Pillow1 from "../../assets/images/Pillow1.jpg";
import Pillow2 from "../../assets/images/Pillow2.jpg";
import Towels1 from "../../assets/images/Towels1.jpg";

const imageList = [
  {
    id: "mainroom1",
    src: MainRoom1,
    alt: "Delicious Dish 1",
    title: "Delicious Dish 1",
    description: "A mouth-watering description of dish 1.",
  },
  {
    id: "mainroom2",
    src: MainRoom2,
    alt: "Beautiful Venue 1",
    title: "Beautiful Venue 1",
    description: "A stunning description of venue 1.",
  },
  {
    id: "mainroom3",
    src: MainRoom3,
    alt: "Beautiful Venue 2",
    title: "Beautiful Venue 2",
    description: "A stunning description of venue 2.",
  },
  {
    id: "room1",
    src: Room1,
    alt: "Beautiful Venue 3",
    title: "Beautiful Venue 3",
    description: "A stunning description of venue 3.",
  },
  {
    id: "room2",
    src: Room2,
    alt: "Beautiful Venue 4",
    title: "Beautiful Venue 4",
    description: "A stunning description of venue 4.",
  },
  {
    id: "twin1",
    src: Twin1,
    alt: "Beautiful Venue 5",
    title: "Beautiful Venue 5",
    description: "A stunning description of venue 5.",
  },
  {
    id: "twin2",
    src: Twin2,
    alt: "Beautiful Venue 6",
    title: "Beautiful Venue 6",
    description: "A stunning description of venue 6.",
  },
  {
    id: "pillow1",
    src: Pillow1,
    alt: "Beautiful Venue 7",
    title: "Beautiful Venue 7",
    description: "A stunning description of venue 7.",
  },
  {
    id: "pillow2",
    src: Pillow2,
    alt: "Beautiful Venue 8",
    title: "Beautiful Venue 8",
    description: "A stunning description of venue 8.",
  },
  {
    id: "towels1",
    src: Towels1,
    alt: "Beautiful Venue 8",
    title: "Beautiful Venue 8",
    description: "A stunning description of venue 8.",
  },
];

const Gallery: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeroSection
        title={t("gallery.title")}
        subtitle={t("gallery.subtitle")}
        backgroundImage={HomeHero4}
      />
      <div className={styles.gallerySection}>
        <GenericGallery images={imageList} />
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
