import React from "react";
import styles from "./FlatSection.module.css";

interface FlatSectionProps {
  alt: string;
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  imageSrc: string;
  height?: string;
  margin?: string;
}

const FlatSection: React.FC<FlatSectionProps> = ({
  alt,
  title,
  description,
  buttonText,
  buttonLink,
  imageSrc,
  height = "h-96",
  margin = "my-16",
}) => {
  return (
    <div className={`${styles.flatSection} ${height} ${margin}`}>
      <div className={styles.imageContainer}>
        <img src={imageSrc} alt={alt} loading="lazy" className={styles.image} />
      </div>
      <div className={styles.overlay}>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{description}</p>
          <a href={buttonLink}>
            <button className={styles.button}>{buttonText}</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FlatSection;
