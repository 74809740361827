import React from "react";
import styles from "./TermsOfServiceContent.module.css";

const TermsOfServiceContent: React.FC = () => {
  return (
    <div className={styles.termsOfServiceContainer}>
      <h1>Terms of Service</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE ONLINE
          ORDERING SYSTEM
        </p>
        <p>
          By accessing, using, or otherwise availing of the Online Ordering
          System, you agree to the terms and conditions set out below, and to
          any other terms and conditions made available to you on the Online
          Ordering System ("Terms of Use") in respect of your use of the Online
          Ordering System.
        </p>
        <p>
          The Terms of Use constitute a binding legal agreement between the
          Store ("us", "we", or "our") and you. Please read the Terms of Use
          carefully, and if you do not agree to any of them, you will not be
          entitled or permitted to access or use the Online Ordering System and
          you must refrain from doing so.
        </p>
        <p>
          Your use of the Online Ordering System is also subject to our{" "}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{" "}
          and our{" "}
          <a href="/cookie-policy" target="_blank" rel="noopener noreferrer">
            Cookie Policy
          </a>
          , and you must also familiarize yourself with these other terms and
          policies before you use the Online Ordering System.
        </p>
        <p>
          We may change the Terms of Use from time to time to the extent
          permitted by applicable law. We will provide you with notice of any
          such change through our website, app, and/or by other means, as
          appropriate. You should review the Terms of Use periodically, and you
          agree that your continued use of the Online Ordering System after we
          publish or send a notice about any changes to the Terms of Use
          constitutes your acceptance of the updated Terms of Use as of their
          effective date. If you object to any changes to the Terms of Use, you
          may stop using the Online Ordering System.
        </p>
      </section>

      <section>
        <h2>1. Purpose</h2>
        <p>
          The purpose of the Online Ordering System is to provide a simple,
          convenient, and cost-effective service that enables you (and other
          customers) to place orders for the delivery or collection of food
          and/or other items (the "Products") with us. You are granted a right
          to use the Online Ordering System pursuant to the Flipdish Terms of
          Service, which form a separate and leading binding agreement between
          you and Flipdish.
        </p>
      </section>

      <section>
        <h2>2. Disclaimer and Liability</h2>
        <p>
          You hereby acknowledge and agree that the Online Ordering System is
          made available for use "as is" and "as available," and we make no
          representations and give no warranties of any kind whatsoever in
          relation to the Online Ordering System and that, without prejudice to
          the generality of the foregoing, we make no warranty or representation
          regarding, and shall have no responsibility for, the accuracy,
          availability, reliability, security, fitness for purpose, performance,
          or continuity of the Online Ordering System or the contents thereof.
          It is your sole responsibility to ensure that the Online Ordering
          System is suitable for your purposes.
        </p>
        <p>
          You acknowledge and agree that to the extent permitted by applicable
          law, the Online Ordering System may at any time be modified or
          withdrawn, on a temporary or permanent basis, and wholly or in part,
          for any reason without notice, and we shall have no liability to you
          or any other party arising out of or in connection with such
          modification or withdrawal. We make no representation or warranty that
          the Online Ordering System will be uninterrupted or error-free or that
          the Online Ordering System will be free of viruses. We do not warrant
          or represent that the Online Ordering System will meet your
          requirements or specifications, or that any errors or bugs in the
          Online Ordering System will be corrected.
        </p>
        <p>
          All representations, warranties, guarantees, terms, and conditions
          whether express or implied by statute or otherwise in relation to the
          Online Ordering System or the information contained therein (including
          any implied warranty of accuracy of data, non-infringement,
          merchantability, or fitness for a particular purpose) are hereby
          excluded to the fullest extent permitted by applicable law.
        </p>
        <p>
          Our total aggregate liability to you whether in contract, tort
          (including negligence) under statute or otherwise arising out of or in
          connection with the Terms of Use, the Online Ordering System, and/or
          the Products shall be limited to €100.
        </p>
        <p>
          We acknowledge that nothing in the Terms of Use shall exclude or limit
          our liability for death or personal injury resulting from our
          negligence or that of our agents or employees.
        </p>
      </section>

      <section>
        <h2>3. Use of Data</h2>
        <p>
          If you enter your payment card details on the Online Ordering System,
          these details will be stored securely by a third-party payment service
          provider. We do not have access to your payment card details, with the
          exception of the last 4 digits of your payment card number and the
          card expiry date, which are used to identify your card and orders.
        </p>
        <p>
          Where you provide your mobile phone number, your name, and your
          address through the Online Ordering System, we will be provided with
          this information so that we can complete your order.
        </p>
        <p>
          For more information on how we may process information about you,
          please see our{" "}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          . Please also see Flipdish's{" "}
          <a
            href="https://www.flipdish.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{" "}
          for information about how they process your information.
        </p>
      </section>

      <section>
        <h2>4. Third-Party Sites</h2>
        <p>
          The Online Ordering System may contain links to other websites. Such
          websites are not under our control, and we are not responsible or
          liable for the practices, content, or availability of such websites.
          The inclusion on the Online Ordering System of links to other websites
          does not imply any endorsement or sponsorship of the content or
          practices on such websites, nor does it imply any affiliation or
          association between us and any operators of such websites. These links
          to websites are provided for your convenience only.
        </p>
        <p>
          If you decide to access a website through a link on the Online
          Ordering System, you do so at your own risk, and we will not be
          responsible or liable to you or any third party for any loss, cost,
          damage, or other liability arising out of or in connection with
          accessing, using, or relying on the website or any content, goods,
          practices, or services available on such website.
        </p>
        <p>
          You are prohibited from linking to the Online Ordering System from any
          other business, service, or other website.
        </p>
      </section>

      <section>
        <h2>5. Obligations and Limitations on Use</h2>
        <p>
          The Online Ordering System is made available for access by you in our
          country of establishment, and we make no representation or warranty
          that any information, materials, or functionality included in the
          Online Ordering System is appropriate or available for use in any
          jurisdiction other than our country of establishment.
        </p>
        <p>
          You agree that any information provided by you on the Online Ordering
          System (including your name and contact details) is true, accurate,
          current, and complete in all respects at the time it is provided, and
          that you will update this information when subsequently using the
          Online Ordering System to the extent that it changes.
        </p>
        <p>By using the Online Ordering System, you also agree that:</p>
        <ul>
          <li>you will comply with the Terms of Use;</li>
          <li>
            you will not impersonate any other person or entity or use a false
            name or a name that you are not authorized to use on the Online
            Ordering System;
          </li>
          <li>you are 18 years of age or older;</li>
          <li>
            you will pay in full for all Products ordered by you through the
            Online Ordering System;
          </li>
          <li>
            you will not use the Online Ordering System to place any speculative
            or false orders;
          </li>
          <li>
            you will not use the Online Ordering System to research our Products
            or the pricing of our Products;
          </li>
          <li>
            you will not use any automated systems, robots, or software to
            extract data from the Online Ordering System;
          </li>
        </ul>
        <p>
          You acknowledge and agree that you may not use the Online Ordering
          System for any unlawful purpose or any purpose that is not authorized
          by Flipdish, including without limitation:
        </p>
        <ul>
          <li>
            transmitting material that constitutes a criminal offense, results
            in civil liability, or otherwise breaches any laws, regulations, or
            codes of practice;
          </li>
          <li>
            interfering with any other person's use or enjoyment of the Online
            Ordering System;
          </li>
          <li>
            damaging, disabling, or impairing the operation, functionality, or
            availability of the Online Ordering System (including by knowingly
            or recklessly introducing viruses, trojans, worms, logic bombs, or
            other malicious or harmful material) or attempting to gain
            unauthorized access to the Online Ordering System or to networks
            connected to it through hacking, spoofing, or any other means;
          </li>
          <li>
            distributing any unlawful, libelous, abusive, threatening, harmful,
            obscene, or otherwise objectionable material on or through the
            Online Ordering System;
          </li>
        </ul>
        <p>
          You may not access the Online Ordering System for any commercial
          purposes (including for the purposes of copying or reproducing any
          material on the Online Ordering System, monitoring the availability,
          performance, or functionality of the Online Ordering System, or for
          any other benchmarking or competitive purposes).
        </p>
      </section>

      <section>
        <h2>6. Orders & Payment</h2>
        <p>
          It is your responsibility to ensure that each order for Products that
          you place through the Online Ordering System is correct prior to
          submitting such order. All questions that you may have regarding
          Products displayed on, or any orders you place through, the Online
          Ordering System should be directed to us using the Store's contact
          details available on our homepage. You acknowledge that Flipdish is
          not able to address any such questions, and you agree that you will
          not contact, or attempt to contact, Flipdish in relation to any such
          questions.
        </p>
        <p>
          Please note that any order you place through the Online Ordering
          System is not accepted until you are sent an SMS confirming that we
          have received and accepted your order (the "SMS"). This means that
          when the Online Ordering System states that your order has been
          confirmed, we may still (at our discretion) choose to decline your
          order until such time as you are sent the SMS. If we are unable to
          accept your order after it has been placed through the Online Ordering
          System, we will contact you to confirm this.
        </p>
        <p>
          Please note that the inclusion of incorrect personal details by you
          when placing an order with us on the Online Ordering System may lead
          to delays in the completion of your order or mean that we are unable
          to complete your order. It is your responsibility to ensure that you
          have included your correct personal details on the Online Ordering
          System (including your delivery address and telephone number), and we
          are not liable for any delay or failure to complete your order due to
          the inclusion of incorrect personal details on the Online Ordering
          System.
        </p>
        <p>
          It is your responsibility to keep your mobile phone secure. You agree
          that you are responsible and liable for all orders and charges placed
          on the Online Ordering System via your mobile phone.
        </p>
        <p>
          Please note that where you have not made payment in full at the time
          of placing your order on the Online Ordering System, you will be
          solely responsible and liable for making all outstanding payments due
          in respect of your order at the time that you collect your order from
          us or we deliver it to you.
        </p>
        <p>
          We reserve the right at our discretion to change the Products and the
          prices of Products available on the Online Ordering System.
        </p>
      </section>

      <section>
        <h2>7. Communication</h2>
        <p>
          You consent to us providing you with notices and other communications
          electronically either through the Online Ordering System or by other
          electronic means, and you acknowledge that all contracts, notices,
          information, and other communications that we provide to you
          electronically comply with any legal requirement that such
          communications be in writing.
        </p>
      </section>

      <section>
        <h2>8. Cancellation</h2>
        <p>
          You acknowledge and agree that we may cancel an order placed by you on
          the Online Ordering System, either before or after acceptance of such
          order, where we believe that inaccurate information was provided as
          part of this order or where circumstances beyond our reasonable
          control occur which prevent or hinder completion of such order.
        </p>
      </section>

      <section>
        <h2>9. Termination</h2>
        <p>
          Each of you and us may terminate the Terms of Use immediately at any
          time and without notice to the other. Upon such termination, you will
          not be entitled or permitted to access and use the Online Ordering
          System. The following clauses of the Terms of Use survive any such
          termination: Clauses 2, 3, 9, 10, 11, and 13.
        </p>
      </section>

      <section>
        <h2>10. General Terms</h2>
        <p>
          If any part of the Terms of Use is deemed unlawful, void, or for any
          reason unenforceable, then that provision shall be deemed severable
          from the Terms of Use and shall not affect the validity and
          enforceability of any of the remaining provisions of the Terms of Use.
        </p>
        <p>
          No failure or delay by us to exercise any right or remedy provided
          hereunder or by law shall constitute a waiver of that or any other
          right or remedy, nor shall it prevent or restrict the further exercise
          of that or any other right or remedy. No single or partial exercise of
          such right or remedy shall prevent or restrict the further exercise of
          that or any other right or remedy.
        </p>
        <p>
          You agree that no partnership, employment, or agency relationship
          exists between you and us as a result of the Terms of Use or your use
          of the Online Ordering System.
        </p>
        <p>
          The Terms of Use constitute the entire agreement between you and us
          regarding the Online Ordering System and supersede and extinguish all
          prior agreements, promises, assurances, warranties, representations,
          and understandings between you and us regarding the Online Ordering
          System.
        </p>
        <p>
          You may not assign, novate, or otherwise transfer the Terms of Use to
          any person without our prior written consent, but you agree that we
          may assign, novate, or otherwise transfer any of our rights or
          obligations under the Terms of Use to our affiliates or a party that
          acquires us.
        </p>
      </section>

      <section>
        <h2>11. Governing Law</h2>
        <p>
          The Terms of Use and any dispute or claim (including non-contractual
          disputes or claims) arising out of or in connection with the Terms of
          Use or their subject matter shall be governed by, and construed in
          accordance with, Irish law, and any such dispute or claim shall be
          resolved exclusively in the courts of Ireland or in the courts of the
          country of our establishment.
        </p>
      </section>

      <section>
        <h2>12. Contact</h2>
        <p>
          All comments, questions, and requests relating to your information are
          welcomed and should be addressed to us using the Store's contact
          details available on our homepage. Please also direct any complaints
          or legal notices to this address.
        </p>
      </section>

      <section>
        <h2>13. Definitions</h2>

        <p>
          <a href="/pt/privacidade" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </p>
      </section>
    </div>
  );
};

export default TermsOfServiceContent;
