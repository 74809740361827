// src/components/Layout/Layout.tsx
import React, { useState } from "react";
import styles from "./Layout.module.css";
import Nav from "../Nav/Nav";
import HamburgerMenu from "../Nav/HamburgerMenu";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  // State to manage the HamburgerMenu visibility
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Handler to open the menu
  const handleHamburgerClick = () => {
    setMenuOpen(true);
  };

  // Handler to close the menu
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <div className={styles.layout}>
      {/* Pass the handler to Nav */}
      <Nav onHamburgerClick={handleHamburgerClick} />
      {/* Render the HamburgerMenu */}
      <HamburgerMenu isOpen={isMenuOpen} onClose={handleMenuClose} />
      <main className={styles.mainContent}>{children}</main>
    </div>
  );
};

export default Layout;
