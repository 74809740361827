// src/pages/Contact/ContactPage.tsx

import React from "react";
import { useTranslation } from "react-i18next";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import VillaEntrance from "../../assets/images/VillaEntrance.jpg";
import GardenView1 from "../../assets/images/GardenView1.jpg";
import styles from "./ContactPage.module.css";
import InspirationSection from "../../components/InspirationSection/InspirationSection";

const ContactPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeroSection
        title={t("contact_page.title")}
        subtitle={t("contact_page.subtitle")}
        backgroundImage={GardenView1}
        variant="default"
      />
      <div className={styles.contactSection}>
        <InspirationSection
          imageSrc={VillaEntrance}
          imageAlt="Restaurant Front"
          title="CONTACTOS"
          subtitle="FALE CONNOSCO"
          phone="+351 217 167 684"
          email="geral@ritelloportugal.com"
          buttons={[
            {
              text: "Direções Google Maps",
              link: "https://maps.app.goo.gl/4tnQWojzG6aqadNd8",
              newTab: true, // Opens in a new tab
            },
          ]}
        />
        <InspirationSection
          embedMap="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3183.3625517826913!2d-8.113207223552045!3d37.07267107217079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1ab5a318c03d05%3A0x6e2f9e825f1506a9!2sApartamento%20Villa%20Columbus!5e0!3m2!1sen!2spt!4v1730933850920!5m2!1sen!2spt"
          imageAlt="Google Map"
          title="Morada"
          subtitle="Apartamento Villa Columbus Bloco 4 A13, Quinta do Romão, Vilamoura"
          buttons={[
            {
              text: "Direções Google Maps",
              link: "https://maps.app.goo.gl/4tnQWojzG6aqadNd8",
              newTab: true, // Opens in a new tab
            },
          ]}
          reverse
        />
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
