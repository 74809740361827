import React from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import PrivacyPolicyContent from "../../components/PrivacyPolicyContent/PrivacyPolicyContent";
import Footer from "../../components/Footer/Footer";
import RestaurantFront from "../../assets/images/RestaurantFront.jpg";

const PrivacyPage: React.FC = () => {
  return (
    <>
      <HeroSection
        title="Politica de Privacidade"
        subtitle=""
        backgroundImage={RestaurantFront}
      />
      <PrivacyPolicyContent />
      <Footer />
    </>
  );
};

export default PrivacyPage;
