import React from "react";
import styles from "./PrivacyPolicyContent.module.css";

const PrivacyPolicyContent: React.FC = () => {
  return (
    <div className={styles.privacyPolicyContainer}>
      <h1>Privacy Policy</h1>

      <section>
        <h2>1. Introduction</h2>
        <p>
          XIANG is committed to respecting the privacy rights of all visitors to
          our Website. This Privacy Policy covers the use of the Website and the
          services provided by IRMAOS JIE LDA trading as XIANG ("XIANG", "we",
          "us", or "our"). XIANG is incorporated with company registration
          number and having its registered address at AZINHAGA DA TORRE DO FATO
          N13 1600-759 LISBOA. This Privacy Policy provides details of the way
          in which we process personal data in line with our obligations under
          the General Data Protection Regulation (No. 2016/679) ("GDPR"), the
          Data Protection Act 2018, and any other laws which apply to us in
          relation to the processing of personal data (collectively referred to
          as "Data Protection Laws"). In this Privacy Policy, "controller",
          "personal data", and "process" (and its derivatives) have the meanings
          given to those terms in Data Protection Laws.
        </p>
        <p>
          Personal data generally means information that can be used to
          individually identify a person, and processing generally covers
          actions that can be performed in connection with data such as
          collection, use, storage, and disclosure. We are the controller of
          your personal data processed in connection with the Website.
        </p>
        <p>
          The purpose of this Privacy Policy is to explain our data processing
          activities, including how and why we process personal data. In
          addition, this Privacy Policy outlines our duties and responsibilities
          regarding the protection of such personal data. The manner in which we
          process personal data will evolve over time and we will update this
          Policy from time to time to reflect changing practices.
        </p>
      </section>

      <section>
        <h2>2. Personal Data We Collect</h2>
        <p>
          We collect personal data about you when you provide it directly to us,
          when third parties provide us with personal data about you, or when
          personal data about you is automatically collected in connection with
          your use of our Website. We do not disclose your personal data to
          third parties, except as outlined in this Privacy Policy.
        </p>

        <h3>2.1 Personal Data Collected Directly From You</h3>
        <p>
          We receive personal data directly from you when you provide it to us
          through placing a food order, including, but not limited to:
        </p>
        <ul>
          <li>name;</li>
          <li>email address;</li>
          <li>mailing address;</li>
          <li>telephone number;</li>
          <li>username; and</li>
          <li>payment details.</li>
        </ul>
        <p>
          You may also include details in your food order that indicate your
          health information or religious beliefs.
        </p>

        <h3>2.2 Personal Data Collected Automatically</h3>
        <p>
          Some personal data is automatically collected when you use the
          Website, such as:
        </p>
        <ul>
          <li>IP address;</li>
          <li>device identifiers;</li>
          <li>browser information; and</li>
          <li>cookie data (as explained in the Cookie Policy).</li>
        </ul>
      </section>

      <section>
        <h2>3. How We Use Personal Data</h2>
        <p>
          We process personal data to operate, improve, understand, and
          personalize our services. For example, we use personal data to:
        </p>
        <ul>
          <li>complete orders including delivery or collection of food;</li>
          <li>personalize content based on your preferences;</li>
          <li>
            contact you about our offers, subject to your marketing preferences;
          </li>
          <li>communicate with you;</li>
          <li>
            protect against or deter fraudulent, illegal, or harmful actions;
          </li>
          <li>respond to user inquiries;</li>
          <li>provide support and assistance;</li>
          <li>comply with our legal or contractual obligations;</li>
          <li>enforce our terms and conditions; and</li>
          <li>resolve disputes.</li>
        </ul>
      </section>

      <section>
        <h2>4. Lawful Basis for Processing</h2>
        <table>
          <thead>
            <tr>
              <th>Purpose of Processing</th>
              <th>Lawful Basis under GDPR</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Administration purposes</td>
              <td>
                Such processing is necessary for the performance of a contract
                between us and you, where necessary for the purpose of complying
                with our legal obligations and where you have consented to
                providing certain information in relation to your food order
                preferences at the time of purchase.
              </td>
            </tr>
            <tr>
              <td>
                Training, quality monitoring, or evaluating the services we
                provide
              </td>
              <td>
                Such processing is necessary for the legitimate interests
                pursued by us in monitoring and improving our Website's services
                and their usage and ensuring that our users use the Website in
                accordance with our terms and conditions and policies.
              </td>
            </tr>
            <tr>
              <td>
                Website services, including for troubleshooting, data analysis,
                and survey purposes
              </td>
              <td>
                We have a legitimate interest in operating and maintaining the
                Website and for related purposes including improving our
                services.
              </td>
            </tr>
            <tr>
              <td>
                Statistical information that cannot be related back to
                individuals to help us improve the services we offer
              </td>
              <td>
                We have a legitimate interest in having access to certain
                analytics to ensure the products and services we provide are
                adequate.
              </td>
            </tr>
            <tr>
              <td>Enforcing and Defending Our Rights</td>
              <td>
                We have a legitimate interest in ensuring that our services and
                the Website are used in accordance with our terms and conditions
                of use and policies. Where necessary for the purpose of
                complying with our legal obligations. Where necessary for the
                purpose of establishing, exercising or defending a legal claim,
                a prospective legal claim, legal proceedings or prospective
                legal proceedings.
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          We will not use your personal data for marketing purposes without your
          consent. If you wish to stop receiving marketing communications from
          us, you can opt out at any time by clicking the "opt-out" link at the
          bottom of any marketing communication from us or by contacting us at
          the e-mail provided on our website.
        </p>
      </section>

      <section>
        <h2>5. Individual Data Subject Rights</h2>
        <p>
          Data Protection Laws provide certain rights in favour of data subjects
          (the "Data Subject Rights").
        </p>
        <p>Data Subject Rights include the right of a data subject to:</p>
        <ul>
          <li>
            receive detailed information on the processing (as per transparency
            obligations on controllers) which we have provided through this
            Privacy Policy;
          </li>
          <li>access personal data;</li>
          <li>rectify or erase personal data (i.e., right to be forgotten);</li>
          <li>restrict processing;</li>
          <li>data portability;</li>
          <li>object to processing; and</li>
          <li>object to automated decision making (including profiling).</li>
        </ul>
        <p>
          These Data Subject Rights will be exercisable by you subject to
          limitations as provided for under Data Protection Laws.
        </p>
        <p>
          You may make a request to us to exercise any Data Subject Right by
          contacting the e-mail address provided on our website. Your request
          will be dealt with in accordance with Data Protection Laws.
        </p>
      </section>

      <section>
        <h2>6. How We Share Data with our Partners</h2>
        <p>
          We do not share any personally identifying data with third parties and
          will not disclose your personal data to any other party except as set
          out in this policy.
        </p>
        <p>
          We and Flipdish, our online ordering partner, are joint controllers of
          your personal data processed in connection with food orders placed
          through the Flipdish Platform on the Website. If you have queries
          regarding how your personal data is processed by Flipdish or wish to
          address your rights, please contact Flipdish.
        </p>
        <p>
          Flipdish acts as a processor in assisting us with managing users'
          consent to our marketing communications. Flipdish also acts as a
          processor when it assists us to manage our analytics.
        </p>
        <p>
          We may be required to disclose personal data in some scenarios e.g.,
          when you violate our terms and conditions or other policies. We may
          disclose such personal data, at our sole discretion, if we believe it
          necessary or appropriate in connection with the investigation of
          fraud, IP infringement, piracy, or other unlawful activity. This may
          require disclosure of your name, address, phone number, email, or
          company name.
        </p>
        <p>
          We may engage other companies and people to perform tasks on our
          behalf (i.e., processors) and may need to share your information with
          them to provide you with our services.
        </p>
        <p>
          In some cases, we may buy or sell its assets which may involve the
          transfer of customer information. We will transfer such information if
          we are acquired by or merged with another company. In this event, we
          will notify you by email.
        </p>
        <p>
          Without limiting the above, in an effort to respect your privacy and
          our ability to keep the community free from bad actors, we will not
          otherwise disclose your personal data to law enforcement, other
          government officials, or other third parties without a court order,
          law enforcement request, legal process, or substantially similar legal
          procedure, except when we believe in good faith that the disclosure of
          information is necessary to protect our rights or the rights of third
          parties, prevent physical harm or financial loss, or to report
          suspected illegal activity.
        </p>
      </section>

      <section>
        <h2>7. Data Retention</h2>
        <p>
          We will keep personal data only for as long as the retention of such
          personal data is deemed necessary for the purposes for which that
          personal data are processed (as described in this Privacy Policy)
          unless we are required to retain your personal data for a longer
          period (e.g., in the event of legal proceedings or investigations).
        </p>
      </section>

      <section>
        <h2>8. Cookies</h2>
        <p>
          We use cookies on this website. A cookie is a small text file that
          identifies your computer on our server. Cookies themselves do not
          identify individual users, only the computer used. Cookies are not
          used to collect personal information.
        </p>
        <p>
          This website uses cookies that help determine the usefulness,
          interest, and number of visits to the website, allowing for faster and
          more efficient navigation, eliminating the need to repeatedly enter
          the same information. The use of cookies on this site will always
          require your prior consent. The cookies we use include:
        </p>
        <ul>
          <li>
            PHPSESSID: A cookie that allows the website to respond to any action
            the user performs on the website, such as completing a survey form.
            The website does not function correctly if this cookie is not used.
          </li>
          <li>
            xms_user: A cookie used by backoffice users. The website functions
            correctly without this cookie.
          </li>
          <li>
            __utma, __utmb, __utmc, __utmz: Cookies starting with "__utm" enable
            the functionality of the analytics software. This software helps
            analyze website visitors and provide anonymous information, such as
            browsers used, returning visitors, and responses to marketing
            activities.
          </li>
          <li>
            __atuvc: The _atuvc cookie is created and read by the website's
            JavaScript, enabling content sharing on social networks via
            "AddThis".
          </li>
        </ul>
        <p>
          All browsers allow users to accept, refuse, or delete cookies,
          including by selecting the appropriate settings in their respective
          browsers. You can configure cookies in the "settings" or "preferences"
          menu of your browser. For more information about cookies, including
          how to see what cookies have been created and how to manage and delete
          them, visit{" "}
          <a
            href="https://www.allaboutcookies.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.allaboutcookies.org
          </a>
          , which includes information on managing your settings for various
          browser providers. Note that disabling cookies may partially or fully
          affect your browsing experience on the website.
        </p>
        <h3>Google Analytics Browser Opt-out</h3>
        <p>
          To provide visitors with greater choice regarding how their data is
          collected by Google Analytics, Google has developed the Google
          Analytics Browser Opt-out Add-on. The add-on communicates with the
          Google Analytics JavaScript (ga.js) to indicate that visit information
          should not be sent to Google Analytics. The Google Analytics Browser
          Opt-out add-on does not prevent information from being sent to the
          site itself or to other web analytics services. Learn more about the
          Google Analytics Browser Opt-out add-on{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </section>

      <section>
        <h2>9. Third-Party Links</h2>
        <p>
          The links on this website may lead to other websites. We are not
          responsible for, nor do we endorse or support the content of these
          websites, their privacy policies, or their practices regarding visitor
          privacy.
        </p>
        <p>
          You should review the linked website's privacy policy before sending
          them any personal data.
        </p>
      </section>

      <section>
        <h2>10. Service-related Announcements and Changes</h2>
        <p>
          Any future changes to this Privacy Policy will be posted on the
          Website and, where appropriate, through email notification.
        </p>
      </section>

      <section>
        <h2>11. Contacting Us</h2>
        <p>
          For further information about this Privacy Policy or the processing of
          your personal data by or on our behalf, please contact us using the
          contact details provided on our website.
        </p>
        <p>
          While you may file a complaint regarding our compliance with Data
          Protection Laws to the Data Protection Commission, we ask that you
          contact us first to allow us the opportunity to address your concerns.
        </p>
        <p>This Policy was last updated on September 2020.</p>
      </section>
    </div>
  );
};

export default PrivacyPolicyContent;
