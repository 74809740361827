import React from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import RestaurantFront from "../../assets/images/RestaurantFront.jpg";
import TermsOfServiceContent from "../../components/TermsOfServiceContent/TermsOfServiceContent";

const TermsOfServicePage: React.FC = () => {
  return (
    <>
      <HeroSection
        title="Termos de Serviço"
        subtitle=""
        backgroundImage={RestaurantFront}
      />
      <TermsOfServiceContent />
      <Footer />
    </>
  );
};

export default TermsOfServicePage;
