// HamburgerMenu.tsx
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./HamburgerMenu.module.css";

interface HamburgerMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ isOpen, onClose }) => {
  const { lng } = useParams<{ lng: string }>();
  const language = lng || "pt";
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.hamburgerMenu} ${isOpen ? styles.menuOpen : ""}`}
      onClick={onClose}
    >
      <div className={styles.menuContent} onClick={(e) => e.stopPropagation()}>
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label="Close Menu"
        >
          &times;
        </button>
        <nav className={styles.navLinks}>
          <NavLink
            to={`/${language}${t("routes.home")}`}
            className={styles.navLink}
            onClick={onClose}
          >
            {t("nav.home")}
          </NavLink>
          <NavLink
            to={`/${language}${t("routes.menu")}`}
            className={styles.navLink}
            onClick={onClose}
          >
            {t("nav.menu")}
          </NavLink>
          <NavLink
            to={`/${language}${t("routes.gallery")}`}
            className={styles.navLink}
            onClick={onClose}
          >
            {t("nav.gallery")}
          </NavLink>
          <NavLink
            to={`/${language}${t("routes.contact")}`}
            className={styles.navLink}
            onClick={onClose}
          >
            {t("nav.contact")}
          </NavLink>
          {/* Add more links as needed */}
        </nav>
      </div>
    </div>
  );
};

export default HamburgerMenu;
