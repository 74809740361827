// src/components/InspirationSection/InspirationSection.tsx

import React from "react";
import styles from "./InspirationSection.module.css";

interface ButtonProps {
  text: string;
  link: string;
  newTab?: boolean; // Optional property to specify if the link opens in a new tab
}

interface ImageSource {
  media: string;
  srcSet: string;
}

interface InspirationSectionProps {
  imageSrc?: string; // Made optional to allow embedding a map instead
  embedMap?: string; // Optional prop for Google Maps embed URL
  imageAlt?: string;
  title: string;
  subtitle: string;
  description?: string;
  phone?: string;
  email?: string;
  buttons?: ButtonProps[]; // Changed from single button to an array of buttons
  sources?: ImageSource[];
  reverse?: boolean; // Optional prop to reverse the layout
}

const InspirationSection: React.FC<InspirationSectionProps> = ({
  imageSrc,
  embedMap,
  imageAlt,
  title,
  subtitle,
  description,
  phone,
  email,
  buttons = [], // Default to empty array
  sources = [],
  reverse = false,
}) => {
  return (
    <section className={`${styles.section} ${reverse ? styles.reverse : ""}`}>
      {/* Image or Map Container */}
      <div className={styles.imageContainer}>
        {embedMap ? (
          <iframe
            src={embedMap}
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title={title}
            className={styles.iframe}
          ></iframe>
        ) : (
          imageSrc && (
            <picture className={styles.picture}>
              {sources.map((source, index) => (
                <source
                  key={index}
                  media={source.media}
                  srcSet={source.srcSet}
                />
              ))}
              <img
                src={imageSrc}
                alt={imageAlt}
                className={styles.image}
                loading="lazy"
              />
            </picture>
          )
        )}
      </div>

      {/* Content Container */}
      <div className={styles.contentContainer}>
        <div className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>
          <h3 className={styles.subtitle}>{subtitle}</h3>
          {description && <p className={styles.description}>{description}</p>}
          {phone && (
            <a href={`tel:${phone}`} className={styles.phone}>
              {phone}
            </a>
          )}
          {email && (
            <a href={`mailto:${email}`} className={styles.email}>
              {email}
            </a>
          )}

          {/* Conditional Buttons Rendering */}
          {buttons.length > 0 && (
            <div className={styles.buttonsContainer}>
              {buttons.map((button, index) => (
                <a
                  key={index}
                  href={button.link}
                  className={styles.buttonLink}
                  target={button.newTab ? "_blank" : "_self"}
                  rel={button.newTab ? "noopener noreferrer" : undefined}
                  aria-label={`${button.text} ${
                    button.newTab ? "(opens in a new tab)" : ""
                  }`}
                >
                  <button type="button" className={styles.button}>
                    {button.text}
                  </button>
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default InspirationSection;
