// src/pages/HomePage.tsx

import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import HeroSection from "../../components/HeroSection/HeroSection";
import InspirationSection from "../../components/InspirationSection/InspirationSection";
import FlatSection from "../../components/FlatSection/FlatSection";
import TripleCardSection from "../../components/TripleCardSection/TripleCardSection";
import Footer from "../../components/Footer/Footer";

// Importing Images
import HomeHero from "../../assets/images/HomeHero.jpg";
// import HomeHero2 from "../../assets/images/HomeHero2.jpg";
import HomeHero3 from "../../assets/images/HomeHero3.jpg";
// import HomeHero4 from "../../assets/images/HomeHero4.jpg";
import MainRoom1 from "../../assets/images/MainRoom1.jpg";
import MainRoom2 from "../../assets/images/MainRoom2.jpg";
import MainRoom3 from "../../assets/images/MainRoom3.jpg";
import VillaEntrance from "../../assets/images/VillaEntrance.jpg";

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  const currentLanguage = lng || "pt";

  // Define the cards data with imported images
  const cards = [
    {
      imageSrc: MainRoom1,
      alt: "Memories from Brazil Menu: Dish.",
      title: "O Menu",
      description:
        "O nosso menu é inspirado pela nossa própria jornada para um aspecto diferente do nosso legado alimentar. Junte-se a nós enquanto celebramos o que torna cada filosofia gastronómica única.",
      buttonText: "Reservar",
      buttonLink: "/pt/contacto",
    },
    {
      imageSrc: MainRoom2,
      alt: "Memories from Brazil Menu: Dish",
      title: "Reconhecimento",
      description:
        "A nossa equipa partilha uma paixão por elevar a cozinha portuguesa ao mais alto nível, ao mesmo tempo que cria experiências memoráveis para todos os nossos convidados.",
      buttonText: "Reservar",
      buttonLink: "/pt/contacto",
    },
    {
      imageSrc: VillaEntrance,
      alt: "Ocean restaurant entrance.",
      title: "Encontre-nos",
      description:
        "Junte-se a nós numa viagem através do oceano, explore os sabores e aromas da nossa história.",
      buttonText: "Contacto",
      buttonLink: "/pt/contacto",
    },
  ];

  return (
    <>
      <HeroSection
        title={t("home.hero_title")}
        subtitle={t("home.hero_subtitle")}
        ctaText={t("home.hero_cta_text")}
        ctaLink={`/${currentLanguage}${t("home.hero_cta_link")}`}
        backgroundImage={HomeHero3}
        variant="home"
      />

      {/* First Inspiration Section with Multiple Buttons */}
      <InspirationSection
        imageSrc={MainRoom1}
        imageAlt="Apartamento T2 Com Vista Piscina"
        title="Apartamento T2 Com Vista Piscina"
        subtitle="Até 5 pessoas"
        description="1 cama de casal grande. 2 camas individuais. 1 sofá-cama."
        buttons={[
          {
            text: "Reservar",
            link: "/pt/contacto",
            // newTab is optional and defaults to false if not provided
          },
        ]}
      />

      {/* Second Inspiration Section with Reversed Layout and Single Button */}
      <InspirationSection
        imageSrc={MainRoom2}
        imageAlt="Apartamento T1 Com Vista Piscina"
        title="Apartamento T1 Com Vista Piscina"
        subtitle="Até 4 pessoas"
        description="1 cama de casal extragrande. 1 sofá-cama."
        buttons={[
          {
            text: "Reservar",
            link: "/pt/contacto",
            newTab: false,
          },
          // Optionally, add more buttons here
        ]}
        reverse
      />
      <InspirationSection
        imageSrc={MainRoom3}
        imageAlt="Apartamento T1 Com Vista Piscina"
        title="Apartamento T1 Com Vista Piscina"
        subtitle="Até 3 pessoas"
        description="1 cama de casal extragrande. 1 sofá-cama."
        buttons={[
          {
            text: "Reservar",
            link: "/pt/contacto",
            newTab: false,
          },
          // Optionally, add more buttons here
        ]}
      />

      <FlatSection
        alt="Preparation"
        title="Galeria"
        description="Veja o que temos para oferecer, em termos de quartos, salas, cozinhas, localização, bem estar, entre muitas outras coisas."
        buttonText="Explorar"
        buttonLink="/pt/galeria"
        imageSrc={HomeHero} // Consider using a different image for variety
        height="h-96" /* Optional: customize as needed */
        margin="my-16" /* Optional: customize as needed */
      />

      {/* Integrate TripleCardSection */}
      <TripleCardSection cards={cards} />

      <Footer />
    </>
  );
};

export default HomePage;
