import React from "react";
import styles from "./TripleCardSectionCard.module.css";

interface TripleCardSectionCardProps {
  imageSrc: string;
  alt: string;
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
}

const TripleCardSectionCard: React.FC<TripleCardSectionCardProps> = ({
  imageSrc,
  alt,
  title,
  description,
  buttonText,
  buttonLink,
}) => {
  return (
    <li className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.header}>
          <div className={styles.imageContainer}>
            <img
              src={imageSrc}
              alt={alt}
              loading="lazy"
              className={styles.image}
            />
          </div>
          <a href={buttonLink} className={styles.titleLink}>
            <h3 className={styles.title}>{title}</h3>
          </a>
        </div>
        <div className={styles.textContent}>
          <p className={styles.description}>{description}</p>
          <a href={buttonLink} className={styles.buttonLink}>
            <button className={styles.button}>{buttonText}</button>
          </a>
        </div>
      </div>
    </li>
  );
};

export default TripleCardSectionCard;
