import React from "react";
import styles from "./HeroSection.module.css";

interface HeroSectionProps {
  title: string;
  subtitle: string;
  ctaText?: string;
  ctaLink?: string;
  backgroundImage: string;
  variant?: "home" | "default";
}

const HeroSection: React.FC<HeroSectionProps> = ({
  title,
  subtitle,
  ctaText,
  ctaLink,
  backgroundImage,
  variant = "default",
}) => {
  return (
    <section
      className={`${styles.heroSection} ${
        variant === "home" ? styles.homeVariant : styles.defaultVariant
      }`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={styles.heroContent}>
        <h1>{title}</h1>
        <p>{subtitle}</p>
        <div style={{ height: "40px" }} />
        {variant === "home" && ctaText && ctaLink && (
          <a href={ctaLink} className={styles.ctaButton}>
            {ctaText}
          </a>
        )}
      </div>
    </section>
  );
};

export default HeroSection;
