import React, { useEffect } from "react";
import { Outlet, useParams, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const supportedLanguages = ["pt", "en", "fr", "it", "es"]; // Define your supported languages

const LanguageWrapper: React.FC = () => {
  const { lng } = useParams<{ lng: string }>();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lng && supportedLanguages.includes(lng)) {
      if (i18n.language !== lng) {
        i18n.changeLanguage(lng);
      }
    }
  }, [lng, i18n]);

  // Conditionally return <Navigate /> if the language is not supported
  if (!lng || !supportedLanguages.includes(lng)) {
    return <Navigate to="/pt" replace />;
  }

  return <Outlet />;
};

export default LanguageWrapper;
