// src/components/TripleCardSection/TripleCardSection.tsx

import React from "react";
import styles from "./TripleCardSection.module.css";
import TripleCardSectionCard from "./TripleCardSectionCard";

interface CardData {
  imageSrc: string;
  alt: string;
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
}

interface TripleCardSectionProps {
  cards: CardData[];
}

const TripleCardSection: React.FC<TripleCardSectionProps> = ({ cards }) => {
  if (cards.length !== 3) {
    console.warn("TripleCardSection expects exactly 3 cards.");
  }

  return (
    <div className={styles.container}>
      <ul className={styles.cardList}>
        {cards.map((card, index) => (
          <TripleCardSectionCard key={index} {...card} />
        ))}
      </ul>
    </div>
  );
};

export default TripleCardSection;
