import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const CookieConsentBanner: React.FC = () => {
  const { t } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  const currentLanguage = lng || "pt";
  const [accepted, setAccepted] = useState(false); // Track if cookies are accepted

  const handleAccept = () => {
    setAccepted(true); // Mark as accepted to remove the banner
    // Optionally, handle additional logic like saving preferences
  };

  return (
    <>
      {!accepted && (
        <CookieConsent
          location="bottom"
          buttonText={t("cookie_banner.accept")}
          onAccept={handleAccept} // Handle accept action
          cookieName="consentimentoDoUtilizador"
          style={{
            background: "var(--dark-color)",
            color: "var(--neutral-white)",
          }}
          buttonStyle={{
            color: "var(--neutral-white)",
            background: "var(--primary-color)",
            padding: "var(--spacer-xs)",
            borderRadius: "var(--button-border-radius)",
          }}
          expires={1}
        >
          {t("cookie_banner.message")}{" "}
          <a
            href={`/${currentLanguage}${t("routes.privacy")}`}
            style={{
              color: "var(--primary-light-blue)",
              textDecoration: "underline",
            }}
          >
            {t("cookie_banner.privacy_policy")}
          </a>
          .
        </CookieConsent>
      )}
    </>
  );
};

export default CookieConsentBanner;
