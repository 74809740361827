// CookieConsentContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the context structure
interface CookieConsentContextProps {
  consent: {
    analytics: boolean;
    marketing: boolean;
  };
  setConsent: React.Dispatch<
    React.SetStateAction<{ analytics: boolean; marketing: boolean }>
  >;
}

// Create context
const CookieConsentContext = createContext<
  CookieConsentContextProps | undefined
>(undefined);

// Provider component with children prop
interface CookieConsentProviderProps {
  children: ReactNode;
}

export const CookieConsentProvider: React.FC<CookieConsentProviderProps> = ({
  children,
}) => {
  const [consent, setConsent] = useState({
    analytics: false,
    marketing: false,
  });

  return (
    <CookieConsentContext.Provider value={{ consent, setConsent }}>
      {children}
    </CookieConsentContext.Provider>
  );
};

// Custom hook to use context
export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);
  if (!context) {
    throw new Error(
      "useCookieConsent must be used within a CookieConsentProvider"
    );
  }
  return context;
};
