// src/components/GenericGallery/GenericGallery.tsx

import React, { useState, useCallback } from "react";
import styles from "./GenericGallery.module.css";

interface ImageItem {
  id: string; // Unique identifier
  src: string;
  alt: string; // Required for accessibility
  title?: string;
  description?: string;
}

interface GenericGalleryProps {
  images: ImageItem[];
  initialVisible?: number; // Number of images to show initially
  loadMoreCount?: number; // Number of images to load on each click
}

const GenericGallery: React.FC<GenericGalleryProps> = ({
  images,
  initialVisible = 12,
  loadMoreCount = 12,
}) => {
  const [visibleImages, setVisibleImages] = useState(initialVisible);
  const [erroredImages, setErroredImages] = useState<Set<string>>(new Set());

  const loadMoreImages = useCallback(() => {
    setVisibleImages((prev) => Math.min(prev + loadMoreCount, images.length));
  }, [loadMoreCount, images.length]);

  const handleImageError = useCallback((id: string) => {
    setErroredImages((prev) => new Set(prev).add(id));
  }, []);

  return (
    <section className={styles.gallery} aria-label="Image Gallery">
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          {images.slice(0, visibleImages).map((image) => (
            <div key={image.id} className={styles.item}>
              {!erroredImages.has(image.id) ? (
                <img
                  src={image.src}
                  alt={image.alt}
                  loading="lazy"
                  onError={() => handleImageError(image.id)}
                  className={styles.image}
                />
              ) : (
                <div className={styles.imagePlaceholder}>
                  <span role="img" aria-label="Image not available">
                    ❌
                  </span>
                </div>
              )}
              <div className={styles.mask}>
                <button
                  className={styles.info}
                  onClick={() => {
                    // Placeholder for future functionality, e.g., opening a modal
                  }}
                  aria-label={`View details for ${image.title || "image"}`}
                >
                  <span></span>
                  <article>
                    <h2>{image.title}</h2>
                    <p>{image.description}</p>
                  </article>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {visibleImages < images.length && (
        <div className={styles.loadMore}>
          <button onClick={loadMoreImages} aria-label="Load more images">
            Load More
          </button>
        </div>
      )}
    </section>
  );
};

export default GenericGallery;
