// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "./locales/en/translation.json";
import ptTranslation from "./locales/pt/translation.json";
import frTranslation from "./locales/fr/translation.json";
import itTranslation from "./locales/it/translation.json";
import esTranslation from "./locales/es/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      pt: { translation: ptTranslation },
      fr: { translation: frTranslation },
      it: { translation: itTranslation },
      es: { translation: esTranslation },
    },
    lng: "pt",
    fallbackLng: "pt", // Set Portuguese as the default language
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ["en", "pt", "fr", "it", "es"],
    detection: {
      order: ["path", "localStorage", "navigator"],
      lookupFromPathIndex: 0, // Adjust if necessary
      caches: ["localStorage"],
    },
  });

export default i18n;
